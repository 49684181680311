import React from 'react'
import Section from './section'
import { ExternalLink } from 'react-external-link'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { FixedImage } from '../models/image'

const caretRightIcon = <FontAwesomeIcon icon={faCaretRight} className="mr-2" />

const QuarkusBookComponent: React.FC<{ coverImage: FixedImage }> = ({
  coverImage,
}) => (
  <Section transparent={true}>
    <div className="row col-mb-50">
      <div className="col-sm-12 col-md-6 col-lg-5 align-items-center text-center">
        <Img fixed={coverImage.childImageSharp.fixed} />
      </div>
      <div className="col-sm-12 col-md-6 col-lg-7">
        <div className="heading-block topmargin-sm">
          <h2>Quarkus 云原生微服务开发实战</h2>
          <span>在云原生时代拥抱原生 Java</span>
        </div>

        <p>
        本书以一个完整的实战应用的开发过程作为主线，介绍如何以 Quarkus 为框架来开发微服务架构的云原生应用。本书介绍了微服务和云原生开发中的各个方面，从微服务的开发和测试，到在 Kubernetes 上部署运行，还包括应用的可观察性、安全和服务调用的健壮性等非功能性需求。通过本书的介绍，你可以了解一个真实的云原生应用的开发过程，并学会如何从头开始开发自己的应用。
        </p>

        <div className="row col-mb-30">
          <div className="col-sm-6 col-md-12 col-lg-6">
            <ul className="mb-0" style={{ listStyle: 'none' }}>
              <li>{caretRightIcon} 云原生微服务概述</li>
              <li>{caretRightIcon} Quarkus开发入门</li>
              <li>{caretRightIcon} Quarkus的依赖注入</li>
              <li>{caretRightIcon} Quarkus微服务的配置</li>
              <li>{caretRightIcon} 同步调用方式 - 餐馆微服务</li>
            </ul>
          </div>

          <div className="col-sm-6 col-md-12 col-lg-6">
            <ul className="mb-0" style={{ listStyle: 'none' }}>
              <li>
                {caretRightIcon}
                异步消息传递 - 订单微服务实现
              </li>
              <li>
                {caretRightIcon}
                反应式微服务 - 送货微服务实现
              </li>
              <li>
                {caretRightIcon}
                Quarkus应用部署
              </li>
              <li>
                {caretRightIcon}
                应用的可观察性
              </li>
              <li>
                {caretRightIcon}
                应用安全及弹性服务
              </li>
              <li>
                {caretRightIcon}
                框架和服务的集成
              </li>
            </ul>
          </div>
        </div>
        <ExternalLink href="https://item.jd.com/13438524.html">
          <button className="mt-4 button button-3d button-large button-rounded">
            去京东购买
          </button>
        </ExternalLink>
      </div>
    </div>
  </Section>
)

const QuarkusBook = () => (
  <StaticQuery
    query={graphql`
      {
        file(relativePath: { glob: "published-books/quarkus.png" }) {
          childImageSharp {
            fixed(height: 540, fit: COVER, quality: 100) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    `}
    render={data => <QuarkusBookComponent coverImage={data.file} />}
  />
)

export default QuarkusBook
