import React from 'react'
import Slider from 'react-slick'
import Img from 'gatsby-image'
import { MdxChatArticleFields } from '../models/mdx'
import { ExternalLink } from 'react-external-link'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const GitChatArticle: React.FC<{ article: MdxChatArticleFields }> = ({
  article,
}) => (
  <div className="oc-item">
    <div className="product">
      <div className="product-image text-center p-2">
        <ExternalLink href={article.frontmatter.link}>
          <Img
            fixed={article.frontmatter.feature_image.childImageSharp.fixed}
          />
        </ExternalLink>
      </div>
      <div className="product-desc center">
        <div className="product-title">
          <h3>
            <ExternalLink href={article.frontmatter.link}>
              {article.frontmatter.title}
            </ExternalLink>
          </h3>
        </div>
        <div>{article.frontmatter.published_at}</div>
        <div className="product-price">￥{article.frontmatter.price}</div>
      </div>
    </div>
  </div>
)

class GitChatArticles extends React.Component<{
  articles: MdxChatArticleFields[]
}> {
  render() {
    const { articles } = this.props
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }
    return (
      <Slider {...settings}>
        {articles.map((article, index) => (
          <GitChatArticle key={index} article={article} />
        ))}
      </Slider>
    )
  }
}

export default GitChatArticles
