import React from 'react'

import Layout from '../layout/layout'
import SEO from '../components/seo'
import { graphql, PageProps } from 'gatsby'
import { MdxArticleFields, MdxChatArticleFields } from '../models/mdx'
import GitChatArticlesWrapper from '../components/gitChatArticlesSection'
import technicalArticles from '../data/technicalArticles'
import { TechnicalArticlesSection } from '../components/technicalArticles'
import ArticlesFlatList from '../components/articlesFlatList'
import QuarkusBook from '../components/quarkusBook'

const displayedArticles = technicalArticles
  .sort((v1, v2) => v2.date.localeCompare(v1.date))
  .slice(0, 6)

type DataProps = {
  allMdx: {
    edges: MdxArticleFields[]
  }
}

const IndexPage: React.FC<PageProps<DataProps>> = ({
  data: {
    allMdx: { edges },
  },
  pageContext,
}) => (
  <Layout>
    <SEO title="首页" />
    <QuarkusBook/>
    <ArticlesFlatList
      title={'最新博客文章'}
      subTitle={'关于不同主题的博客文章'}
      articles={edges}
      pageContext={pageContext}
      disablePagination={true}
      showLinkToAll={true}
    />
    <GitChatArticlesWrapper />
    <TechnicalArticlesSection
      articles={displayedArticles}
      count={technicalArticles.length}
    />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/articles/" } }
      sort: { order: DESC, fields: [frontmatter___published_at] }
      limit: 5
    ) {
      edges {
        node {
          ...MdxArticleFields
        }
      }
    }
  }
`
