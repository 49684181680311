import React from 'react'
import GitChatArticles from './gitchatArticle'
import { MdxChatArticleFields } from '../models/mdx'
import Section from './section'
import { graphql, StaticQuery } from 'gatsby'

const GitChatArticlesSection: React.FC<{
  articles: MdxChatArticleFields[]
}> = ({ articles }) => (
  <Section>
    <div className="heading-block center">
      <h3>付费文章</h3>
      <span>GitChat上的付费文章</span>
    </div>
    <GitChatArticles articles={articles} />
  </Section>
)

const GitChatArticlesWrapper = () => (
  <StaticQuery
    query={graphql`
      query {
        allMdx(
          filter: { fileAbsolutePath: { regex: "/chats/" } }
          sort: { fields: frontmatter___published_at, order: DESC }
        ) {
          nodes {
            ...MdxChatArticleFields
          }
        }
      }
    `}
    render={data => <GitChatArticlesSection articles={data.allMdx.nodes} />}
  />
)

export default GitChatArticlesWrapper
